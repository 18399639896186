app-media-player, app-media-player-v2 {
    display: block;
    width: 100%;
    height: 100%;
    @include tizen {
        position: fixed;
        background: $kuki-black;
    }
    @include webos {
        position: fixed;
        background: $kuki-black;
    }

    .shaka-player {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    #subtitleElement {
        white-space: pre;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 1.5%;
        direction: ltr;
        writing-mode: horizontal-tb;
        unicode-bidi: plaintext;
        text-align: center;
        @include setProperty(font-size, 90px);
    }

    #subtitleImage {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto 1.5%;
    }

    .locked-player {

        .locked-content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            text-align: center;
            @include setColumns(width, 20);
            @include setRows(height, 8);
            @include setRows(line-height, 8);
            @include setProperty(font-size, 80px);
            @include setProperty(border-radius, 5px);
            @include setColumns(padding-left padding-right, 1);
            background: rgba($some-gray, 0.5);
            font-weight: 900;

            .icon {
                @include setProperty(width, 84px);
                @include setProperty(height, 80px);
                @include setProperty(margin-right, 20px);
                @include setProperty(top, 5px);
            }
        }
    }

    .broadcast-gap-container {
        .broadcast-gap-action {
            position: absolute;
            z-index: 0;
            @include setRows(bottom, 23);
            @include setColumnsWithGutter(right, 3);
        }
    }

    .broadcast-gap-container-freetime {
        .broadcast-gap-action {
            position: absolute;
            z-index: 0;
            @include setRows(bottom, 23);
            @include setColumnsWithGutter(left, 3);
        }
        app-countdown-box {
            @include setProperty(width, 1000px);
        }
    }
}
