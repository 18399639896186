app-screen-saver {
    .screen-saver-slide {
        position: fixed;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-color: $backround-main;

        .screen-saver-title {
            @include h1();
            text-transform: uppercase;
            position: absolute;
            @include setRows(top, 47);
            @include setColumnsWithOffset(left, 3);
            @include setColumnsWithOffset(width, 28);
        }

        app-rating {
            position: absolute;
            @include setRows(top, 54);
            @include setColumnsWithOffset(left, 3);
        }

        .screen-saver-description {
            @include p();
            position: absolute;
            @include setRows(top, 58);
            @include setColumnsWithOffset(left, 3);
            @include setColumns(width, 20);
            @include setRows(height, 10);
        }

        .screen-saver-button {
            position: absolute;
            @include setRows(top, 40);
            @include setColumnsWithOffset(left, 3);
            @include setGutter(margin-right);
            @include setRows(height, 6);
            @include setProperty(padding-left padding-right, 50px);
            @include setProperty(border-radius, 10px);
            background-color: rgba($some-gray, 0.5);
            transform: translateZ(0);

            .icon-play-button-big {
                @include setProperty(margin-right, 32px);
                vertical-align: middle;
            }

            .button-label {
                text-align: center;
                @include setRows(line-height, 6);
                @include setProperty(font-size, 72px);
                font-weight: 800;
                text-transform: uppercase;
                color: $kuki-white;
                white-space: nowrap;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    //.info-box {
    //    position: absolute;
    //    text-align: left;
    //    @include setColumnsWithOffset(left, 3);
    //    @include setRows(top, 3);
    //    @include setProperty(border-radius, 10px);
    //    //@include setProperty(width, 700px);
    //    @include setProperty(font-size, 72px);
    //
    //    &.info-box-br {
    //        top: auto;
    //        left: auto;
    //        @include setColumns(right, 1);
    //        @include arris {
    //            @include setColumns(right, 2);
    //        }
    //        @include setRows(bottom, 3);
    //    }
    //
    //    .dayname {
    //        font-weight: 900;
    //    }
    //
    //    .daycontainer {
    //        @include setProperty(max-width, 1200px);
    //    }
    //
    //    .weather {
    //        @include setRows(margin-top, 1);
    //        background-color: $kuki-purple-2;
    //        @include setProperty(border-radius, 10px);
    //        overflow: hidden;
    //        @include setProperty(padding, 20px);
    //        @include setProperty(padding-left padding-right, 50px);
    //        transform: translateZ(0);
    //    }
    //
    //    .city-temp {
    //        @include setProperty(max-width, 1000px);
    //        text-align: center;
    //        float: left;
    //        .temp {
    //            font-weight: 900;
    //            @include setProperty(font-size, 72px);
    //        }
    //    }
    //
    //    .image-link-factory {
    //        position: relative;
    //        float: left;
    //        @include setProperty(margin-left, 80px);
    //        @include setProperty(margin-top, 20px);
    //        @include setProperty(width, 170px);
    //        @include setProperty(height, 170px);
    //        background-size: contain;
    //    }
    //}

    .slide-general {
        width: 100%;
        height: 100%;
        @include res-720 {
            background: url('~assets/overlay/screensaver.1280.png') no-repeat;
        }
        @include res-1080 {
            background: url('~assets/overlay/screensaver.1920.png') no-repeat;
        }
    }

    .profile {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        @include setRows(top, 6);
        @include setProperty(width, 720px);

        .profile-label {
            text-align: center;
            @include setProperty(font-size, 90px);
            font-weight: 900;
            text-transform: uppercase;
        }

        app-profile-tile {
            display: block;
            @include setProperty(font-size, 72px);
            @include setProperty(width, 480px);
            @include setProperty(height, 480px);
            position: relative;
            margin: auto;
            @include setRows(margin-top, 1);

            .image-link-factory {
                border-radius: 50%;
                background-position: center center;
                background-size: cover;
                border: 1px solid #fff;
            }

            .tile-label {
                display: none;
            }
        }
    }

    //.datetime {
    //    display: table;
    //    position: absolute;
    //    height: 100%;
    //
    //    left: 0;
    //    right: 0;
    //    margin: auto;
    //    @include setColumns(width, 12);
    //
    //    .vcenter {
    //        display: table-cell;
    //        vertical-align: middle;
    //    }
    //
    //    //.datetime-box {
    //    //    background: $kuki-purple-2;
    //    //    @include setProperty(padding-top padding-bottom, 30px);
    //    //    @include setProperty(border-radius, 20px);
    //    //}
    //
    //    .date {
    //        @include setProperty(font-size, 90px);
    //        @include setProperty(width, 480px);
    //        margin: auto;
    //    }
    //
    //    .time {
    //        @include setProperty(font-size, 150px);
    //        @include setProperty(width, 585px);
    //        font-weight: 900;
    //        margin: auto;
    //    }
    //}

    .date-dayname {
        display: table;
        position: absolute;
        height: 100%;
        @include setProperty(font-size, 90px);
        @include setColumnsWithOffset(left, 1);
        @include setColumns(width, 13);

        .vcenter {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        .date-dayname-box {
            background: $kuki-purple-2;
            @include setProperty(padding-top padding-bottom, 40px);
            @include setProperty(padding-left padding-right, 50px);
            @include setProperty(border-radius, 20px);
            display: inline-block;
            @include setColumns(max-width, 8);

            .date {
                font-weight: 900;
                @include setProperty(font-size, 90px);
            }

            .dayname {
                @include setRows(margin-top, 1);
                @include setProperty(font-size, 72px);
                font-weight: 300;
                span {
                    font-weight: 900;
                }
            }
        }
    }

    .weather {
        display: table;
        position: absolute;
        height: 100%;
        @include setColumns(width, 13);
        @include setColumnsWithOffset(right, 1);

        .vcenter {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        .weather-box {
            background: $kuki-purple-2;
            @include setProperty(padding-top padding-bottom, 40px);
            @include setProperty(padding-left padding-right, 50px);
            @include setProperty(border-radius, 20px);
            display: inline-block;
            @include setColumns(max-width, 8);
        }

        .weather-data {

            .temperature {
                font-weight: 900;
                @include setProperty(font-size, 150px);
                @include setProperty(line-height, 150px);
                display: inline-block;
                vertical-align: middle;
                @include setProperty(margin-right, 50px);
            }

            .weather-icon {
                position: relative;
                margin: auto;
                display: inline-block;
                vertical-align: middle;

                .image-link-factory {
                    margin: auto;
                    position: relative;
                    @include setProperty(width, 200px);
                    @include setProperty(height, 200px);
                    background-size: contain;
                }
            }
        }

        .city {
            @include setProperty(font-size, 72px);
            @include setRows(margin-top, 1);
        }
    }

    .news {
        background: $kuki-pink;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        @include setRows(bottom, 5);
        @include setProperty(font-size, 72px);
        font-weight: 300;
        @include setProperty(padding-top padding-bottom, 50px);
        @include setProperty(padding-left padding-right, 50px);
        @include setColumns(width, 32);
        text-align: center;
        @include setProperty(border-radius, 10px);
    }
}
