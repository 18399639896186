$new-theme: false;

/* Primary colors */
$kuki-purple: #1c1130;
$kuki-purple-2: #3d3252;
$kuki-purple-3: #35284f;

$backround-main: #291340;

$kuki-white: #FFFFFF;
$kuki-turquoise: #40F0D3;
$some-gray: #d8d8d8;
$some-red: #d0021b;
$some-purple: #bd10e0;
$kuki-pink: #E50B76;
$kuki-pink-2: #FD238E;
$kuki-light: #928CA6;
$some-purple2: #2c204b;
$some-box-shadow: #404040;
$some-purple4: #494059;
$left-panel-bg: #312A43;

$kuki-green: #00e89c;

$wizard-gray: #f1f1f1;
$osd-line: #979797;
$kuki-black: #000;

$grad-background-1: linear-gradient(241deg, rgb(71, 18, 67), rgb(42, 18, 51) 23%, rgb(27, 19, 35) 51%, rgb(40, 20, 89));
$grad-info-panel:
    linear-gradient(to top, rgb(41, 19, 64) , rgba(41, 19, 64, 0)),
    linear-gradient(to right, rgb(41, 19, 64), rgba(41, 19, 64, 0));

$grad-tile: linear-gradient(to bottom, rgba($kuki-purple, 0), $kuki-purple);
$grad-osd-preview: linear-gradient(to top, $kuki-purple, rgba($kuki-purple, 0) 50%);

$active-cursor-box-shadow: 0 get-vw(8px) get-vw(50px) 0 rgba(20, 12, 34, 0.7);
$kuki-tile-box-shadow: 0 get-vw(4px) get-vw(40px) 0 rgba(20, 12, 34, 0.5);
$kuki-tile-cursor-box-shadow: get-vw(20px) get-vw(20px) get-vw(40px) 0 rgba(#404040, 0.2);
$kuki-settings-panel-item-box-shadow: 0 get-vw(4px) get-vw(20px) 0 rgba(20, 12, 34, 0.5);

$kuki-modal-box-shadow: get-vw(20px) get-vw(20px) get-vw(90px) 0 rgba(0, 0, 0, 0.5);
$kuki-modal-bg: #23154c;

// from web to dashboard management
$kuki-form-input: #8e8e93;

$some-purple-2: #1d142d;
$kuki-lila: #3d18b7;
$kuki-yellow-2: #f27f20;
$kuki-purple-4: #493a6c;

$kuki-epg: #52496C;

// kid
$kuki-kid-background-main: #38225f;

// senior
$kuki-senior-background-main: #1C1130;
$kuki-senior-secondary: #403850;

// new colors system
$kuki-dark: #1C1130;
$kuki-button: #271C3A; // missing JM

$kuki-yellow: #f5a623;
$kuki-yellow-3: #fcdf03;

