app-countdown-box {
    display: block;
    @include setRows(height, 4);
    @include setProperty(width, 870px);
    @include setProperty(padding-left padding-right, 40px);
    @include setProperty(border-radius, 10px);
    background-color: rgba($some-gray, 0.2);
    text-align: left;
    @include setRows(line-height, 4);
    @include setProperty(font-size, 56px);
    font-weight: 800;
    color: $kuki-white;
    .progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        @include setProperty(border-radius, 10px);
        background-color: rgba($some-gray, 0.5);
    }
    .label {
        position: relative;
        z-index: 1;
        white-space: nowrap;
    }
}
