app-notifications, .notifications-wrapper {
    position: fixed;
    @include setRows(top, 3);
    // in design
    // @include setColumnsWithGutter(right, 1);
    @include setColumns(right, 1);
    @include arris {
        @include setColumns(right, 2);
    }
    z-index: 2000;
}

.notification {
    overflow: hidden;
    max-width: 100%;
    @include setColumns(width, 13);
    @include setRows(margin-bottom, 2);
    @include setRows(padding-top padding-bottom, 2);
    @include setProperty(padding-left padding-right, 80px);
    border: solid $kuki-white;
    @include setProperty(border-width, 5px);
    @include setProperty(border-radius, 10px);
    box-shadow: 0 get-vw(4px) get-vw(40px) 0 rgba(20, 12, 34, 0.3);
    background: #f27f20;
    color: $kuki-white;
    &.notification-alert {
        background: #e50b76;
    }
    &.notification-message {
        background: #f27f20;
    }
    &.notification-news {
        background: #3622fc;
    }
    &.notification-transparent {
        background: rgba(#e50b76, 0.6);
    }
    .notification-icon {
        float: left;
        @include setProperty(width, 141px);
        @include setProperty(height, 141px);
        background-size: contain;
        &.notification-icon-message {
            background-image: url('~assets/notifications/message.png');
        }
        &.notification-icon-alert {
            background-image: url('~assets/notifications/alert.png');
        }
        &.notification-icon-news {
            background-image: url('~assets/notifications/news.png');
        }
    }
    .notification-content {
        @include setProperty(margin-left, 197px);
        .notification-title {
            @include h2();
            text-transform: uppercase;
            @include setProperty(font-size, 60px);
            @include setProperty(line-height, 60px);
            @include setRows(margin-bottom, 1);
        }
        .notification-text {
            @include p();
            @include setProperty(font-size, 45px);
            @include setProperty(line-height, 60px);
        }
        app-buttons-panel {
            @include setRows(margin-top, 1);
        }
    }
}
